<template>
  <base-card
    dark
    class="show-room"
  >
    <v-row
      class="fill-height no-gutters"
      align="center"
    >
      <v-col
        md="5"
        sm="12"
        offset-md="1"
      >
        <v-img
          :src="require('@/assets/botellas/botella_black_4_2.png')"
          class="darken-4"
          height="700"
          width="100%"
          contain
        />
      </v-col>
      <v-col
        md="6"
        sm="12"
      >
        <v-container>
          <v-row>
            <v-col
              cols="11"
            >
              <h2>2022</h2>
              <v-row>
                <medalla
                  v-for="medalla in this.get2022Row()"
                  :key="medalla.id"
                  :value="medalla"
                  :cols_number="3"
                  class="darken-4"
                  contain
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
            >
              <h2>2021</h2>
              <v-row>
                <medalla
                  v-for="medalla in this.get2021Row()"
                  :key="medalla.id"
                  :value="medalla"
                  :cols_number="3"
                  class="darken-4"
                  contain
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
            >
              <h2>2020</h2>
              <v-row>
                <medalla
                  v-for="medalla in this.get2020Row()"
                  :key="medalla.id"
                  :value="medalla"
                  :cols_number="3"
                  class="darken-4"
                  contain
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="11"
            >
              <h2>2019</h2>
              <v-row>
                <medalla
                  v-for="medalla in this.get2019Row()"
                  :key="medalla.id"
                  :value="medalla"
                  :cols_number="3"
                  class="darken-4"
                  contain
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
            >
              <h2>2018</h2>
              <v-row>
                <medalla
                  v-for="medalla in this.get2018Row()"
                  :key="medalla.id"
                  :value="medalla"
                  :cols_number="3"
                  class="darken-4"
                  contain
                />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'MedalShowroom',
    components: {
      Medalla: () => import('@/components/base/Medalla'),
    },
    computed: {
      ...mapState(['medallas']),
    },
    methods: {
      get2022Row () {
        return this.medallas.slice(0, 2)
      }, 
      get2021Row () {
        return this.medallas.slice(2, 4)
      },
      get2020Row () {
        return this.medallas.slice(4, 8)
      },
      get2019Row () {
        return this.medallas.slice(8, 10)
      },
      get2018Row () {
        return this.medallas.slice(10, 12)
      },
    },
  }
</script>

<style scoped>
  .show-room {
    background-color: #000000;
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .medalla-margin {
    margin-left: 25px;
  }
</style>
